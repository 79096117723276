/* This example requires Tailwind CSS v2.0+ */
import React, { useState, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, MailIcon, XCircleIcon } from '@heroicons/react/outline';
import { useSelector, useDispatch } from 'react-redux';
import {
    checkClientRelationship,
    createClientRelationship,
    searchClients
} from '../../Utils/accountUtil';

const InviteClientForm = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('grant.d@gmail.com');
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    //TODO: Figure out best way to do the disabling / greying of the search button

    async function searchClient(e) {
        setLoading(true);
        setError(false);
        e.preventDefault();

        //do check of eisting client list first before doing search

        const existingClient = await searchClients(email);

        if (existingClient.length > 0) {
            const checkIfRelationshipExists = await checkClientRelationship(
                existingClient[0].id
            );

            if (checkIfRelationshipExists === false) {
                const createdRelationship = await createClientRelationship(
                    existingClient[0]
                );

                setLoading(false);
                dispatch({
                    type: 'PT/CLIENT_INVITE_SUCCESS',
                    payload: createdRelationship[0]
                });

                dispatch({
                    type: 'PT/CLIENT_RELATIONSHIPS',
                    payload: createdRelationship
                });
            } else {
                setError('Client is already a follower');
                setLoading(false);
            }
        } else {
            setError(`Client doesn't appear to be a member of PTDiary`);
            setLoading(false);
        }
    }

    return (
        <>
            <div className={'mb-3'}>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                    >
                        Invite New Client
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Enter your clients email to invite them to PTDiary
                            and begin their training.
                        </p>
                    </div>

                    <div>
                        <div className="mt-1 relative rounded-md m-5 pt-3 pb-3">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <MailIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                type="email"
                                name="email"
                                id="email"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                placeholder="you@example.com"
                                aria-invalid="true"
                                aria-describedby="email-error"
                            />
                        </div>

                        {error ? (
                            <div className="rounded-md bg-red-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon
                                            className="h-5 w-5 text-red-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">
                                            {error}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-3 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    disabled={isLoading || email === null}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={(e) => searchClient(e)}
                >
                    {isLoading ? 'Please wait...' : 'Add Client'}
                </button>

                <button
                    ref={ref}
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() =>
                        dispatch({
                            type: 'PT/CLIENT_INVITE_SHOW',
                            payload: false
                        })
                    }
                >
                    Cancel
                </button>
            </div>
        </>
    );
});

const SuccessfulClientSubmission = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();

    return (
        <>
            <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                    />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                    >
                        Client successfully invited
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Once your client accepts your request - you'll be
                            able to begin managemening your clients goals
                            including scheduling and invoicing.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    ref={ref}
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() =>
                        dispatch({
                            type: 'PT/CLIENT_INVITE_SHOW',
                            payload: false
                        })
                    }
                >
                    Done
                </button>
            </div>
        </>
    );
});

export default function InviteClientModal({ clientList }) {
    //console.log('invite()', clientList);
    const inviteClientSelector = useSelector(
        (state) => state.pt.clients.toggleInvite
    );

    const inviteClientResultSelector = useSelector(
        (state) => state.pt.clients.status
    );

    const dispatch = useDispatch();

    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={inviteClientSelector} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={() =>
                    dispatch({ type: 'PT/CLIENT_INVITE_SHOW', payload: false })
                }
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            {/* */}

                            {inviteClientResultSelector === 'success' ? (
                                <SuccessfulClientSubmission
                                    ref={cancelButtonRef}
                                />
                            ) : null}

                            {inviteClientResultSelector === null ? (
                                <InviteClientForm ref={cancelButtonRef} />
                            ) : null}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
