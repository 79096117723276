import { combineReducers } from "redux";
import sessionReducer from "../Model/Session/sessionReducer";
import profileReducer from "../Model/Profile/profileReducer";
import ptReducer from "../Model/PT/ptReducer";

export const rootReducer = combineReducers({
  session: sessionReducer,
  profile: profileReducer,
  pt: ptReducer,
});

// export default rootReducer;
