import { useNavigate } from "react-router-dom";

export default function PTHomeSection({ tasks }) {
  const navigate = useNavigate();

  return (
    <div
      className={
        "grid grid-rows-3 sm:grid-rows-2 lg:grid-rows-1 grid-flow-col gap-5"
      }
    >
      {tasks.map((task, key) => {
        return (
          <div key={key} className="bg-white shadow">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {task.title}
              </h3>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>{task.description}</p>
                </div>
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                  <button
                    onClick={() => navigate(task.href)}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  >
                    RESOLVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
