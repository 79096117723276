/* This example requires Tailwind CSS v2.0+ */
import Home from "../Sections/Client/Home";
import TrainingPlan from "../Sections/Client/TrainingPlans";
import PT from "../Sections/Client/PT";
import Profile from "../Sections/Client/Profile";
import DashboardLayout from "../Layout/DashboardLayout";
import { useSelector } from "react-redux";
import {
  CalendarIcon,
  HomeIcon,
  UserGroupIcon,
  SwitchHorizontalIcon,
  LogoutIcon,
  BellIcon,
} from "@heroicons/react/outline";
import { useEffect, useState } from "react";

const primaryNavigationClient = [
  {
    name: "Overview",
    href: "/client",
    icon: HomeIcon,
    current: true,
    reference: "HOME",
  },
  {
    name: "Training Plans",
    href: "/client/plans",
    icon: CalendarIcon,
    current: false,
    reference: "PLANS",
  },
  {
    name: "Your PT",
    href: "/client/pt",
    icon: UserGroupIcon,
    current: false,
    reference: "PT",
  },
  {
    name: "Your Profile",
    href: "/client/profile",
    icon: UserGroupIcon,
    current: false,
    reference: "PROFILE",
  },
];

const secondaryNavigationClient = [
  { name: "Logout", href: "/logout", icon: LogoutIcon, current: false },
];

const secondaryNavigationPT = [
  {
    name: "PT Mode",
    href: "/pt",
    icon: SwitchHorizontalIcon,
    current: true,
  },
  {
    name: "Notifications",
    href: "/client",
    icon: BellIcon,
    current: true,
  },
  { name: "Logout", href: "/logout", icon: LogoutIcon, current: false },
];

export default function Example({ section }) {
  const [activeSectionComponent, setActiveComponent] = useState(<>Loading</>);
  const [primaryMenuItems, setPrimaryMenu] = useState(primaryNavigationClient);
  const [sectionTitle, setSectionTitle] = useState(null);

  useEffect(() => {
    const updateMenuItem = (reference) => {
      const updatedMenuList = primaryMenuItems;
      updatedMenuList.forEach((item) => {
        if (item.reference === reference) {
          item.current = true;
        } else {
          item.current = false;
        }
      });
      setPrimaryMenu(updatedMenuList);
    };

    switch (section) {
      case "HOME":
        setActiveComponent(<Home />);
        updateMenuItem(section);
        setSectionTitle("");
        break;
      case "PLANS":
        setActiveComponent(<TrainingPlan />);
        updateMenuItem(section);
        setSectionTitle("Your Training Plans");
        break;
      case "PROFILE":
        setActiveComponent(<Profile />);
        updateMenuItem(section);
        setSectionTitle("Your Profile");
        break;
      case "PT":
        setActiveComponent(<PT />);
        updateMenuItem(section);
        setSectionTitle("Your PT");
        break;
      default:
        break;
    }
  }, [section, primaryMenuItems]);

  const profileSelector = useSelector((state) => state.profile);
  const isPT = profileSelector.pt_role;

  return (
    <DashboardLayout
      primaryMenu={primaryMenuItems}
      secondaryMenu={isPT ? secondaryNavigationPT : secondaryNavigationClient}
      pageTitle={sectionTitle}
    >
      {activeSectionComponent}
    </DashboardLayout>
  );
}
