import { supabase } from "../Utils/supabaseClient";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountSelector from "../Sections/AccountSelector";
import CreateProfile from "./CreateProfile";
import AccountLayout from "../Layout/AccountLayout";
import Account from "../Sections/Account";

export default function LoginPage() {
  const profileSelector = useSelector((state) => state.profile);

  const isPT = profileSelector.pt_role;
  const isClient = profileSelector.client_role;

  return (
    <AccountLayout>
      {!supabase.auth.session() ? <Account /> : null}
      {profileSelector === "NO_PROFILE" ? <CreateProfile /> : null}
      {isPT ? <AccountSelector /> : null}
      {isClient && !isPT ? <Navigate to="/client" /> : null}
    </AccountLayout>
  );
}
