import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { supabase } from "../Utils/supabaseClient";

export default function LogoutPage() {
  useEffect(() => {
    supabase.auth.signOut();
  }, []);

  return <Navigate to="/" />;
}
