//todo reducer is overwriting the other state

export default function sessionReducer(state = {}, action) {
  let updatedState;
  switch (action.type) {
    case "SESSION/SET":
      updatedState = action.payload;
      return updatedState;
    case "SESSION/RESET":
      return (state = {});
    default:
      return state;
  }
}
