import { useState } from 'react';
import { createProfile, registerUser } from '../Utils/accountUtil';
import { XCircleIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import AccountPicker from '../Components/AccountPicker/AccountPicker';

const RegistrationSuccessful = () => {
    return (
        <>
            <div className="mt-8">
                <div className="mt-6">
                    <h2 className="text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl pt-2">
                        Thanks! That's you registered.
                    </h2>

                    <h2 className="text-1xl font-extrabold text-gray-900 sm:text-1xl sm:tracking-tight lg:text-1xl pt-4">
                        Please check your emails to confirm your account.
                    </h2>

                    <h2 className="text-1xl font-extrabold text-gray-900 sm:text-1xl sm:tracking-tight lg:text-1xl pt-10">
                        <Link
                            to="/client"
                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Back to Homepage
                        </Link>
                    </h2>
                </div>
            </div>
        </>
    );
};

export default function CreateProfile({ typeChosen }) {
    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [successfullySubmitted, setSuccessfulSubmission] = useState(false);

    const submitProfile = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        const registeredUser = await registerUser(email, password);

        if (registeredUser.hasOwnProperty('id')) {
            const clientObject = {
                id: registeredUser.id,
                email: email,
                password: password,
                first_name: firstName,
                last_name: lastName,
                pt_role: typeChosen === 'pt' ? true : false
            };

            const submitUser = await createProfile(clientObject);

            if (submitUser instanceof Array) {
                setSuccessfulSubmission(true);
            } else {
                setError(submitUser.message);
                setSubmitting(false);
            }
        } else {
            setError(registeredUser.message);
            setSubmitting(false);
        }
    };

    if (successfullySubmitted) return <RegistrationSuccessful />;

    return (
        <>
            <AccountPicker typeChosen={typeChosen} />
            <div className="mt-12">
                <form
                    onSubmit={(e) => submitProfile(e)}
                    className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 mt-10"
                >
                    <div className="sm:col-span-2">
                        <label
                            htmlFor="city"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Email
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                type="text"
                                value={email}
                                name="email"
                                id="email"
                                autoComplete="email"
                                className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label
                            htmlFor="city"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Password
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="address-level2"
                                className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="first_name"
                            className="block text-sm font-medium text-gray-700"
                        >
                            First name
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                type="text"
                                name="first_name"
                                id="first_name"
                                autoComplete="given-name"
                                className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="last_name"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Last name
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                type="text"
                                name="last_name"
                                id="last_name"
                                autoComplete="family-name"
                                className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            />
                        </div>
                    </div>

                    {error ? (
                        <div className="sm:col-span-2 py-5">
                            <div className="rounded-md bg-red-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon
                                            className="h-5 w-5 text-red-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">
                                            {error}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className="sm:col-span-2 py-5">
                        <button
                            disabled={isSubmitting}
                            className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {isSubmitting ? (
                                <span>Submitting...</span>
                            ) : (
                                <span>Create Account</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}
