/* eslint-disable jsx-a11y/anchor-is-valid */
import InviteClientModal from '../../../Components/InviteClient/InviteClientModal';
import { useDispatch } from 'react-redux';
import { getClientRelationships } from '../../../Utils/accountUtil';
import { UserAddIcon, FilterIcon, ChevronDownIcon, PencilIcon } from '@heroicons/react/solid';
import { useParams, Link } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';
import { useEffect, Fragment, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';

//TODO: Should move this to a seperate component
const ClientCardContainer = ({ clientList, isLoading }) => {
    if (isLoading) {
        return <>Loading....</>;
    } else {
        return (
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                {clientList.map((person) => (
                    <li
                        key={person.id}
                        className="col-span-1 flex flex-col text-center bg-white rounded-lg border divide-y divide-gray-200"
                    >
                        <div className="flex-1 flex flex-col p-4">
                            <img
                                className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
                                src={person.imageUrl}
                                alt=""
                            />
                            <h3 className="mt-6 text-gray-900 text-lg font-medium">{person.name}</h3>
                            <dl className="flex-grow flex flex-col justify-between">
                                <dt className="sr-only">Title</dt>
                                <dt className="sr-only">Role</dt>
                                <dd className="mt-3 mb-2">
                                    <div className="mt-1">
                                        {person.tags.map((tags, key) => {
                                            return (
                                                <span
                                                    key={key}
                                                    className="inline-flex items-center px-2.5 py-0.5 text-xs font-light m-0.5 border  text-stone-800 rounded-full"
                                                >
                                                    {tags}
                                                </span>
                                            );
                                        })}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                        <div>
                            <div className="-mt-px flex divide-x divide-gray-200">
                                {person.active ? (
                                    <>
                                        <div className="-ml-px w-0 flex-1 flex">
                                            <Link
                                                to={'/pt/clients/aeb4b092-99f3-4ea9-8148-5c104b22d8ec'}
                                                className="focus:outline-none relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-br-lg rounded-bl-lg hover:text-blue-900 text-blue-700"
                                            >
                                                <PencilIcon className="w-5 h-5 text-blue-700" aria-hidden="true" />
                                                <span className="ml-3">View Profile</span>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <div className="-ml-px w-0 flex-1 flex">
                                        <a className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-br-lg rounded-bl-lg hover:text-gray-500 text-red-800">
                                            <XIcon className="w-5 h-5 text-red-800" aria-hidden="true" />
                                            <span className="ml-3">Cancel Invite</span>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }
};

let filters = [
    {
        id: 'status',
        name: 'Status',
        options: [
            { value: 'active', label: 'Active', checked: false, isTag: false },
            { value: 'client_accepted', label: 'Invited', checked: false, isTag: false },
            { value: 'holiday', label: 'Holiday', checked: false, isTag: false }
        ]
    },
    {
        id: 'location',
        name: 'Location',
        options: [
            { value: 'local', label: 'Local', checked: false, isTag: false },
            { value: 'virtual', label: 'Virtual', checked: false, isTag: false }
        ]
    },
    {
        id: 'tags',
        name: 'Tags',
        options: []
    }
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function ClientsSection() {
    const dispatch = useDispatch();
    let { clientID } = useParams();

    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const [currentClients, setClients] = useState(null);
    const [clientFilters, setFilters] = useState(filters);
    const [activeFilter, setActiveFilter] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [hasClientSelected, setSelectedClient] = useState(false);

    useEffect(() => {
        if (clientID) {
            setSelectedClient(true);
        } else {
            setSelectedClient(false);
        }

        if (currentClients === null && isLoading) {
            if (clientID) {
                const selectedClientFilter = { client_id: 'aeb4b092-99f3-4ea9-8148-5c104b22d8ec' };
                populateClients(selectedClientFilter);
            } else {
                populateClients();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientID, currentClients, isLoading]);

    async function populateClients(ativeFilterItems) {
        // setActiveFilter(ativeFilterItems);
        const rawResult = await getClientRelationships(ativeFilterItems || null);

        const clientList = rawResult.map((client) => {
            return {
                id: client.id,
                name: client.profiles.first_name + ' ' + client.profiles.last_name,
                active: client.active,
                paid: client.paid,
                virtual: client.virtual,
                local: client.local,
                holiday: client.holiday,
                client_accepted: client.client_accepted,
                imageUrl: client.profiles.avatar_url,
                tags: client.tags
            };
        });

        //When new results are received, update the tags
        //TODO: Need to update but keep the active selections. So compare.
        //  console.log('effect() - filters', filters);
        if (filters[2].options.length === 0) {
            let tagList = [];
            clientList.forEach((client, index) => {
                client.tags.forEach((tag) => {
                    let tagItem = { value: tag, label: tag, checked: false, isTag: true, isVisible: true };
                    tagList.push(tagItem);
                });
            });
            filters[2].options = tagList;
        } else {
            console.log('filters() should update', filters);

            filters[2].options.forEach((tag, index) => {
                // console.log('tag', tag);
                // if (tag.checked) {
                //     console.log('tag.checked', tag.checked);
                // }
            });
        }

        //TODO should merge tagList with filters[2].options so it gets latest and sets active

        //Should build the filter here

        setFilters(filters);
        setClients(clientList);
        setLoading(false);
    }

    const manageClientFilter = (sectionID, optionID) => {
        const existingOptionSelection = clientFilters[sectionID].options[optionID].checked;
        clientFilters[sectionID].options[optionID].checked = !existingOptionSelection;

        setFilters(clientFilters);

        let activeFilterItems = {};
        let filterList = { standard: [], tags: [] };

        //get active values for each filter
        clientFilters.forEach((filter, index) => {
            filter.options.forEach((option, index) => {
                if (filter.options[index].checked) {
                    let filterName = option.value;
                    let filterValue = option.checked;

                    //Uses client_accepted so if invited its client_accepted false
                    //Can remove this now?
                    if (filterName === 'client_accepted') {
                        filterValue = !filterValue;
                    }

                    /*
                     ** Use below as way to manage filters and tags. Use the If...Else to handle which to push to
                     ** Then update the accountsUtil to receive the new format of type:array
                     ** let exampleObj = {standard:[{}], tags:[{}]}
                     */

                    if (option.isTag) {
                        filterList.tags.push(filterName);
                    } else {
                        filterList.standard.push(filterName);
                    }

                    let filterItems = {};

                    filterItems[filterName] = filterValue;
                    Object.assign(activeFilterItems, filterItems);
                }
            });
        });

        setActiveFilter(filterList);
        populateClients(filterList);
    };

    let hasResults = currentClients && currentClients.length > 0 ? true : false;

    if (isLoading) {
        return <>Loading...</>;
    }

    //TODO: Should return the client profile page here but pass the clientObject
    if (hasResults && hasClientSelected) {
        return <>hello</>;
    }

    return (
        <div className="bg-white">
            <InviteClientModal clientList={currentClients} />
            <div>
                {/* Mobile filter dialog */}
                <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
                                <div className="px-4 flex items-center justify-between">
                                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                    <button
                                        type="button"
                                        className="-mr-2 w-10 h-10 p-2 flex items-center justify-center text-gray-400 hover:text-gray-500"
                                        onClick={() => setMobileFiltersOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                {/* Filters */}
                                <form className="mt-4">
                                    {clientFilters.map((section) => (
                                        <Disclosure
                                            as="div"
                                            key={section.name}
                                            className="border-t border-gray-200 pt-4 pb-4"
                                        >
                                            {({ open }) => (
                                                <fieldset>
                                                    <legend className="w-full px-2">
                                                        <Disclosure.Button className="w-full p-2 flex items-center justify-between text-gray-400 hover:text-gray-500">
                                                            <span className="text-sm font-medium text-gray-900">
                                                                {section.name}
                                                            </span>
                                                            <span className="ml-6 h-7 flex items-center">
                                                                <ChevronDownIcon
                                                                    className={classNames(
                                                                        open ? '-rotate-180' : 'rotate-0',
                                                                        'h-5 w-5 transform'
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        </Disclosure.Button>
                                                    </legend>
                                                    <Disclosure.Panel className="pt-4 pb-2 px-4">
                                                        <div className="space-y-6">
                                                            {section.options.map((option, optionIdx) => (
                                                                <div key={option.value} className="flex items-center">
                                                                    <input
                                                                        id={`${section.id}-${optionIdx}-mobile`}
                                                                        name={`${section.id}[]`}
                                                                        defaultValue={option.value}
                                                                        type="checkbox"
                                                                        key={`${section.id}-${optionIdx}`}
                                                                        className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                                                                    />
                                                                    <label
                                                                        htmlFor={`${section.id}-${optionIdx}-mobile`}
                                                                        className="ml-3 text-sm text-gray-500"
                                                                    >
                                                                        {option.label}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Disclosure.Panel>
                                                </fieldset>
                                            )}
                                        </Disclosure>
                                    ))}
                                </form>
                            </div>
                        </Transition.Child>
                    </Dialog>
                </Transition.Root>
                {/* end Mobile */}

                <main className="max-w-7xl mx-auto px-1 sm:px-1 lg:px-8">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 items-baseline justify-between pb-6 border-b border-gray-200">
                        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 text-center sm:text-left">
                            Clients
                        </h1>

                        <div className="flex items-center justify-center sm:justify-end pt-3 pb-3 sm:p-0">
                            <button
                                onClick={() =>
                                    dispatch({
                                        type: 'PT/CLIENT_INVITE_SHOW',
                                        payload: true
                                    })
                                }
                                type="button"
                                className="inline-flex items-center px-3 py-2 font-small md:px-6 md:py-3 border border-transparent shadow-sm text-base md:font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-1"
                            >
                                <UserAddIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                                Add New Client
                            </button>

                            <button
                                type="button"
                                className="inline-flex items-center px-3 py-2 font-small md:px-6 md:py-3 border border-transparent shadow-sm text-base md:font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-1 lg:hidden"
                                onClick={() => setMobileFiltersOpen(true)}
                            >
                                <span className="sr-only">Filters</span>
                                <FilterIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                                Filter
                            </button>
                        </div>
                    </div>

                    <section aria-labelledby="products-heading" className="pt-6 pb-24">
                        <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
                            <div className="hidden lg:block">
                                <form className="divide-y divide-gray-200 space-y-10">
                                    {clientFilters.map((section, sectionIdx) => (
                                        <div
                                            key={`${section.id}-${sectionIdx}`}
                                            className={sectionIdx === 0 ? null : 'pt-10'}
                                        >
                                            <fieldset>
                                                <legend className="block text-sm font-medium text-gray-900">
                                                    {section.name}
                                                </legend>
                                                <div className="pt-6 space-y-3">
                                                    {section.options.map((option, optionIdx) => (
                                                        <div key={optionIdx} className="flex items-center">
                                                            <input
                                                                onChange={() =>
                                                                    manageClientFilter(sectionIdx, optionIdx)
                                                                }
                                                                id={`${section.id}-${optionIdx}`}
                                                                name={`${section.id}[]`}
                                                                defaultValue={option.checked}
                                                                checked={option.checked}
                                                                type="checkbox"
                                                                className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                                                            />
                                                            <label
                                                                htmlFor={`${section.id}-${optionIdx}`}
                                                                className="ml-3 text-sm text-gray-600"
                                                            >
                                                                {option.label}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </fieldset>
                                        </div>
                                    ))}
                                </form>
                            </div>

                            <div className="lg:col-span-3">
                                {hasResults && hasClientSelected === false ? (
                                    <ClientCardContainer
                                        clientList={currentClients}
                                        isLoading={isLoading}
                                        filters={activeFilter}
                                    />
                                ) : null}

                                {hasResults === false && hasClientSelected === false ? <>No Results</> : null}
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
}

// function getTags(clients) {
//     console.log('getTags() does clients have entries', clients);

//     let currentFilterTags = [];

//     clients.forEach((client) => {
//         client.tags.forEach((tag) => {
//             currentFilterTags.push(tag);
//         });
//     });

//     setFilterTags(currentFilterTags);

//     let tagCollection = [];
//     // let tagOptionItem = { value: 'local', label: 'Cardio', checked: false };

//     //check tagOptionItem with tags array and update the filters with the new tags
//     currentFilterTags.forEach((tag) => {
//         let tagOptionItem = {};
//         tagOptionItem.value = tag;
//         tagOptionItem.label = tag;
//         tagOptionItem.checked = true;
//         tagCollection.push(tagOptionItem);
//     });

//     let clonedFilters = filters;
//     clonedFilters[2].options = tagCollection;

//     setFilters(clonedFilters);
// }
