import DashboardLayout from '../Layout/DashboardLayout';
import { useSelector } from 'react-redux';
import { CalendarIcon, HomeIcon, UserGroupIcon, SwitchHorizontalIcon, LogoutIcon } from '@heroicons/react/outline';
import Home from '../Sections/PT/Home';
import ClientsContainer from '../Sections/PT/Clients/ClientsContainer';
import Plans from '../Sections/PT/TrainingPlans';
import Profile from '../Sections/PT/Profile';
import Website from '../Sections/PT/Website';
import { useEffect, useState } from 'react';

const primaryNavigationPT = [
    {
        name: 'Overview',
        href: '/pt',
        icon: HomeIcon,
        current: true,
        reference: 'HOME'
    },
    {
        name: 'Clients',
        href: '/pt/clients',
        icon: UserGroupIcon,
        current: false,
        reference: 'CLIENTS'
    },
    {
        name: 'Training Plans',
        href: '/pt/plans',
        icon: CalendarIcon,
        current: false,
        reference: 'PLANS'
    },
    {
        name: 'Your Profile',
        href: '/pt/profile',
        icon: UserGroupIcon,
        current: false,
        reference: 'PROFILE'
    },
    {
        name: 'Your Website',
        href: '/pt/website',
        icon: UserGroupIcon,
        current: false,
        reference: 'WEBSITE'
    }
];

const secondaryNavigationPT = [
    {
        name: 'Client Mode',
        href: '/client',
        icon: SwitchHorizontalIcon,
        current: true
    },
    { name: 'Logout', href: '/logout', icon: LogoutIcon, current: false }
];

export default function PTPage({ section }) {
    const [primaryMenuItems, setPrimaryMenu] = useState(primaryNavigationPT);
    const [activeSectionComponent, setActiveComponent] = useState(<>Loading</>);
    const [sectionTitle, setSectionTitle] = useState(null);

    const profileSelector = useSelector((state) => state.profile);
    const isPT = profileSelector.pt_role;

    useEffect(() => {
        const updateMenuItem = (reference) => {
            const updatedMenuList = primaryMenuItems;
            updatedMenuList.forEach((item) => {
                if (item.reference === reference) {
                    item.current = true;
                } else {
                    item.current = false;
                }
            });
            setPrimaryMenu(updatedMenuList);
        };

        if (profileSelector) {
            switch (section) {
                case 'HOME':
                    setActiveComponent(<Home />);
                    updateMenuItem(section);
                    setSectionTitle(``);
                    break;
                case 'CLIENTS':
                    setActiveComponent(<ClientsContainer />);
                    updateMenuItem(section);
                    setSectionTitle('');
                    break;
                case 'PLANS':
                    setActiveComponent(<Plans />);
                    updateMenuItem(section);
                    setSectionTitle('Training Plans');
                    break;
                case 'PROFILE':
                    setActiveComponent(<Profile />);
                    updateMenuItem(section);
                    setSectionTitle('Your Profile');
                    break;
                case 'WEBSITE':
                    setActiveComponent(<Website />);
                    updateMenuItem(section);
                    setSectionTitle('Your Website');
                    break;
                default:
                    break;
            }
        }
    }, [section, primaryMenuItems, profileSelector]);

    if (isPT === false) {
        return <DashboardLayout pageTitle={'Uh oh!'}>User does not have PT role</DashboardLayout>;
    }

    if (isPT && profileSelector) {
        return (
            <DashboardLayout
                primaryMenu={primaryMenuItems}
                secondaryMenu={secondaryNavigationPT}
                pageTitle={sectionTitle}
                profile={profileSelector}
            >
                {activeSectionComponent}
            </DashboardLayout>
        );
    }

    return <DashboardLayout>Loading....</DashboardLayout>;
}
