import { useEffect } from 'react';
import { supabase } from './Utils/supabaseClient';
import './App.css';
import LoginPage from './Pages/LoginPage';
import PT from './Pages/PT';
import Client from './Pages/Client';
import LogoutPage from './Pages/LogoutPage';
import RegisterPage from './Pages/RegisterPage';
import { userExists } from './Utils/accountUtil';

import { BrowserRouter as Router, Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

function RequireAuth() {
    const hasSession = supabase.auth.session();
    let location = useLocation();
    const profileSelector = useSelector((state) => state.profile);

    if (!hasSession || profileSelector === 'NO_PROFILE') {
        return <Navigate to="/" state={{ from: location }} />;
    }

    return <Outlet />;
}

function App() {
    const dispatch = useDispatch();
    const sessionSelector = useSelector((state) => state.session);

    useEffect(() => {
        async function fetchProfile() {
            const getUserProfileResult = await userExists();
            return getUserProfileResult;
        }

        supabase.auth.onAuthStateChange(async (_event, session) => {
            if (_event === 'SIGNED_IN' || _event === 'TOKEN_REFRESHED') {
                dispatch({ type: 'SESSION/SET', payload: session });
            } else {
                dispatch({ type: 'SESSION/RESET' });
                dispatch({ type: 'PROFILE/RESET' });
            }
        });

        if (supabase.auth.session()) {
            fetchProfile().then((data) => {
                if (!data) {
                    dispatch({ type: 'SESSION/RESET' });
                } else {
                    dispatch({ type: 'PROFILE/SET', payload: data });
                }
            });
        }
    }, [dispatch, sessionSelector]);

    return (
        <div className="h-screen">
            <Router>
                <div className="h-screen">
                    <Routes>
                        <Route path="/" element={<LoginPage />} />
                        <Route path="/logout" element={<LogoutPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/register/:type" element={<RegisterPage />} />
                        <Route element={<RequireAuth />}>
                            <Route path="/pt" element={<PT section={'HOME'} />} />
                            <Route path="/pt/clients" element={<PT section={'CLIENTS'} />} />
                            <Route path="/pt/clients/:clientID" element={<PT section={'CLIENTS'} />} />
                            <Route path="/pt/plans" element={<PT section={'PLANS'} />} />
                            <Route path="/pt/profile" element={<PT section={'PROFILE'} />} />
                            <Route path="/pt/website" element={<PT section={'WEBSITE'} />} />
                            <Route path="/client" element={<Client section={'HOME'} />} />
                            <Route path="/client/plans" element={<Client section={'PLANS'} />} />
                            <Route path="/client/profile" element={<Client section={'PROFILE'} />} />
                            <Route path="/client/pt" element={<Client section={'PT'} />} />
                        </Route>
                    </Routes>
                </div>
            </Router>
        </div>
    );
}

export default App;
