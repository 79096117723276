import TaskList from "../../Components/TaskList/TaskList";

const taskItemList = [
  {
    title: "Complete your website",
    description:
      "Decide what information you want to share on your website. This can be achiements you've made with clients, contact information, or your current availability. Check it out.",
    href: "/pt/website",
  },
  {
    title: "Add your first client",
    description:
      "Start helping your clients achieve their goals by inviting them to PTD. You can manage your fitness plans, check-ins or billing with an onboarded client",
    href: "/pt/clients",
  },
  {
    title: "Create a training plan",
    description:
      "Start helping your clients achieve their goals by inviting them to PTD. You can manage your fitness plans, check-ins or billing with an onboarded client",
    href: "/pt/plans",
  },
];

export default function PTHomeSection() {
  return (
    <>
      <TaskList tasks={taskItemList} />
    </>
  );
}
