import { useState } from 'react';
import { supabase } from '../Utils/supabaseClient';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/solid';

export default function Example() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (email) => {
        try {
            setLoading(true);
            const { session, error } = await supabase.auth.signIn(
                {
                    email,
                    password
                },
                {
                    redirectTo: window.location.origin
                }
            );
            if (error) {
                setError(error.message);
                setLoading(false);
                throw error;
            } else {
                dispatch({ type: 'SESSION/SET', payload: session });
            }
        } catch (error) {
            // alert(error.error_description || error.message);
        }
    };

    function registrationNavigation() {
        navigate('/register');
    }

    return (
        <>
            <div>
                <div>
                    <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                        PTDIARY
                    </h2>
                    <h2 className="text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl pt-2 inline-flex">
                        Get started
                    </h2>
                </div>
            </div>

            <div className="mt-8">
                <div className="mt-6">
                    <form action="#" method="POST" className="space-y-6">
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="space-y-1">
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    value={password}
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        {error ? (
                            <div className="rounded-md bg-red-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon
                                            className="h-5 w-5 text-red-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">
                                            {error}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div>
                            <button
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleLogin(email);
                                }}
                                disabled={loading}
                                className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Login
                            </button>

                            <button
                                type="button"
                                onClick={(e) => registrationNavigation()}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-2"
                            >
                                No Account? Register here
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
