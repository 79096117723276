import { supabase } from './supabaseClient';

export const logoutAccount = async () => {
    await supabase.auth.signOut();
};

export const userExists = async () => {
    try {
        const user = await supabase.auth.user();

        let { data, error, status } = await supabase.from('profiles').select(`*`).eq('id', user.id).single();

        //406 for no data returned
        if (error && status !== 406) {
            return error;
        }

        if (data) {
            return data;
        }
    } catch (error) {}
};

export const getClientRelationships = async (filterOptions, tags) => {
    try {
        console.log('getClientRelationships()');
        const user = await supabase.auth.user();

        let filterParams = { pt_id: user.id };

        //Only match on standard filter items
        let results = await supabase
            .from('pt_followers')
            .select(
                `id, client_id, active, client_accepted, virtual, local, holiday, tags,
                profiles (
                    first_name,
                    last_name,
                    avatar_url
                )`
            )
            .match(filterParams)
            .then(({ data, error, status }) => {
                if (data) {
                    return data;
                } else {
                    return error;
                }
            });

        return results;
    } catch (error) {
        return error;
    }
};

export const searchClients = async (clientEmail) => {
    try {
        let { data, error, status } = await supabase
            // .from('pt_followers')
            .from('profiles')
            .select(`id`)
            .eq('email', clientEmail);

        //406 for no data returned
        if (error && status !== 406) {
            return error;
        }

        if (status === 406) {
            //no rows found. So no Profile.
            return 'NO_PROFILE';
        }

        if (data) {
            return data;
        }
    } catch (error) {}
};

export const checkClientRelationship = async (clientID) => {
    const user = await supabase.auth.user();

    try {
        let { data, error, status } = await supabase
            // .from('pt_followers')
            .from('pt_followers')
            .select(`client_id`, 'pt_id')
            .match({ client_id: clientID, pt_id: user.id });

        //406 for no data returned
        if (error && status !== 406) {
            return error;
        }

        if (data.length > 0) {
            return true;
        } else {
            return false;
        }
    } catch (error) {}
};

export async function createClientRelationship(client_id) {
    try {
        const user = supabase.auth.user();

        const updates = {
            client_id: client_id.id,
            pt_id: user.id
        };

        let { error, data } = await supabase.from('pt_followers').upsert(updates, {
            returning: 'representation' // Don't return the value after inserting
        });

        if (error) {
            throw error;
        }

        if (data && data.length > 0) {
            const updatedRelationships = await getClientRelationships(user.id);
            return updatedRelationships;
        }
    } catch (error) {
        return error;
    }
}

export async function registerUser(email, password) {
    try {
        const { user, error } = await supabase.auth.signUp({
            email: email,
            password: password
        });

        if (user && user.hasOwnProperty('id')) {
            return user;
        } else {
            return error;
        }
    } catch (e) {
        return e;
    }
}

export async function createProfile({ id, first_name, last_name, email, pt_role }) {
    try {
        const updates = {
            id,
            first_name,
            last_name,
            email,
            pt_role,
            updated_at: new Date()
        };

        let { error, data } = await supabase.from('profiles').upsert(updates, {
            returning: 'representation' // Don't return the value after inserting
        });

        if (error) {
            throw error;
        }

        if (data && data.length > 0) {
            return data;
        }
    } catch (error) {
        return error;
    }
}
