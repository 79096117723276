import { createStore } from 'redux';
import { rootReducer } from './reducer';
import { devToolsEnhancer } from 'redux-devtools-extension';

const defaultState = {
    session: {},
    profile: {},
    pt: {}
};

export const configureStore = () => {
    return createStore(rootReducer, defaultState, devToolsEnhancer({}));
};

export const store = createStore(rootReducer, devToolsEnhancer({}));
