import TaskList from "../../Components/TaskList/TaskList";

const taskItemList = [
  {
    title: "Find a PT",
    description:
      "You don't currently have a Personal Trainer. Why not find one using the PT browser?",
    href: "/client/pt",
  },
  {
    title: "Complete your profile",
    description:
      "The more information you add to your profile - the easier it is for a PT to understand your goals",
    href: "/client/profile",
  },
];

export default function CLientHomeSection() {
  return <TaskList tasks={taskItemList} />;
}
