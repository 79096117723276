const defaultPTState = {
    clients: {
        toggleInvite: false,
        status: null,
        data: null,
        type: 'active',
        relationships: []
    }
};

export default function ptReducer(state = defaultPTState, action) {
    const clientState = state.clients;
    let updatedClientState;
    switch (action.type) {
        case 'PT/CLIENT_RELATIONSHIPS':
            updatedClientState = clientState.relationships = action.payload;
            return { ...state, updatedClientState };
        case 'PT/CLIENT_TAB':
            updatedClientState = clientState.type = action.payload;
            return { ...state, updatedClientState };
        case 'PT/CLIENT_INVITE_SHOW':
            if (action.payload) {
                updatedClientState =
                    ((clientState.toggleInvite = action.payload),
                    (clientState.status = null));
            } else {
                updatedClientState = clientState.toggleInvite = action.payload;
            }

            return { ...state, updatedClientState };
        case 'PT/CLIENT_INVITE_SUCCESS':
            updatedClientState =
                ((clientState.data = action.payload),
                (clientState.status = 'success'));

            return { ...state, updatedClientState };
        case 'PT/CLIENT_INVITE_FAILURE':
            updatedClientState =
                ((clientState.data = action.payload),
                (clientState.status = 'failed'));
            return { ...state, updatedClientState };
        default:
            return state;
    }
}
