import { useParams } from 'react-router-dom';
import CreateProfile from './CreateProfile';
import AccountLayout from '../Layout/AccountLayout';

export default function RegisterPage() {
    const { type } = useParams();

    return (
        <AccountLayout>
            <div>
                <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                    PTDIARY
                </h2>
            </div>

            <CreateProfile typeChosen={type} />
        </AccountLayout>
    );
}
