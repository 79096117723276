import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const accountTypes = [
    {
        id: 1,
        title: 'Client',
        description:
            'Recommended for those wishing to find a new PT or use the services of an existing PT.',
        users: 'Free',
        url: 'client'
    },
    {
        id: 2,
        title: 'Personal Trainer',
        description:
            'Fee based account for fitness professionals who wish to manage their clients.',
        users: 'Monthly fee',
        url: 'pt'
    }
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function CreateProfile({ typeChosen = false }) {
    const [chosenAccountType, setChosenAccountType] = useState(typeChosen);
    const navigate = useNavigate();

    useEffect(() => {
        //Get url params
        if (typeChosen === 'client') {
            setChosenAccountType(accountTypes[0]);
        } else if (typeChosen === 'pt') {
            setChosenAccountType(accountTypes[1]);
        } else {
            setChosenAccountType(false);
        }
    }, [typeChosen]);

    function setType(selection) {
        navigate(`/register/${selection.url}`);
    }

    return (
        <>
            <div className="mt-12">
                <div className="mt-5 mb-5">
                    <RadioGroup
                        value={chosenAccountType}
                        onChange={(e) => setType(e)}
                    >
                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                            {accountTypes.map((mailingList) => (
                                <RadioGroup.Option
                                    key={mailingList.id}
                                    value={mailingList}
                                    className={({ checked, active }) =>
                                        classNames(
                                            checked
                                                ? 'border-transparent'
                                                : 'border-gray-300',
                                            active
                                                ? 'ring-2 ring-indigo-500'
                                                : '',
                                            'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                        )
                                    }
                                >
                                    {({ checked, active }) => (
                                        <>
                                            <div className="flex-1 flex">
                                                <div className="flex flex-col">
                                                    <RadioGroup.Label
                                                        as="span"
                                                        className="block text-sm font-medium text-gray-900"
                                                    >
                                                        {mailingList.title}
                                                    </RadioGroup.Label>
                                                    <RadioGroup.Description
                                                        as="span"
                                                        className="mt-1 flex items-center text-sm text-gray-500"
                                                    >
                                                        {
                                                            mailingList.description
                                                        }
                                                    </RadioGroup.Description>
                                                    <RadioGroup.Description
                                                        as="span"
                                                        className="mt-6 text-sm font-medium text-gray-900"
                                                    >
                                                        {mailingList.users}
                                                    </RadioGroup.Description>
                                                </div>
                                            </div>
                                            <CheckCircleIcon
                                                className={classNames(
                                                    !checked ? 'invisible' : '',
                                                    'h-5 w-5 text-indigo-600'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <div
                                                className={classNames(
                                                    active
                                                        ? 'border'
                                                        : 'border-2',
                                                    checked
                                                        ? 'border-indigo-500'
                                                        : 'border-transparent',
                                                    'absolute -inset-px rounded-lg pointer-events-none'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>
                </div>
            </div>
        </>
    );
}
