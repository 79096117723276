export default function profileReducer(state = {}, action) {
  switch (action.type) {
    case "PROFILE/SET":
      return action.payload;
    case "PROFILE/SET_MISSING":
      return "NO_PROFILE";
    case "PROFILE/RESET":
      return {};
    default:
      return state;
  }
}
