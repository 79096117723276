/* This example requires Tailwind CSS v2.0+ */
import { UserIcon, UserGroupIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
const items = [
  {
    title: "Client",
    description: "Manage your own fitness",
    icon: UserIcon,
    background: "bg-pink-500",
    href: "/client",
  },
  {
    title: "Personal Trainer",
    description: "Manage your clients",
    icon: UserGroupIcon,
    background: "bg-yellow-500",
    href: "/pt",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

//todo - Take the profile selector. And check if they have the PT_ROLE and offer multiple options, else load client
export default function Example() {
  return (
    <>
      <div>
        <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
          PTDIARY
        </h2>
        <h2 className="text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl pt-2">
          Choose your role
        </h2>
      </div>

      <ul className="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-1">
        {items.map((item, itemIdx) => (
          <li className="flow-root" key={itemIdx}>
            <div className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
              <div
                className={classNames(
                  item.background,
                  "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                )}
              >
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-900">
                  <Link to={item.href} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {item.title}
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </h3>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
